var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-menu',{ref:"HeaderMenu"}),_c('div',{staticClass:"view-content"},[_vm._m(0),_c('div',{staticClass:"l_r_space t__space informatization_1"},[_c('h2',{staticClass:"nav__title"},[_vm._v("合规管理的困境")]),_c('div',{staticStyle:{"padding-top":"0.7rem"}},[_c('a-row',{attrs:{"gutter":112,"type":"flex"}},_vm._l((_vm.statusList),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":12,"sm":12,"md":12,"lg":12,"xl":8,"xxl":8}},[_c('div',{staticClass:"bgz",style:({
                background: ("url(" + (item.img_url) + ")"),
                width: item.width,
                height: item.height,
              })}),_c('div',[_c('h3',{staticClass:"col_title1 b_b_space font-size_26"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 text_center font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)]),_c('div',{staticClass:"l_r_space t__space bgc_f4fbff informatization_2"},[_c('h2',{staticClass:"nav__title"},[_vm._v("我们能帮您做什么")]),_c('div',{staticClass:"tab_informatization",staticStyle:{"padding-top":"0.7rem"}},[_c('a-tabs',{attrs:{"default-active-key":"1","tabBarStyle":{
            borderBottom: 'none',
          }}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"满足业务层级多元化"}},[_c('div',{staticClass:"space_p_t-six"},[_c('a-row',{attrs:{"gutter":165,"type":"flex"}},_vm._l((_vm.helpList),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":8,"xl":8,"xxl":8}},[_c('div',{staticClass:"bgz",style:({
                      background: ("url(" + (item.img_url) + ")"),
                      width: '76px',
                      height: '76px',
                    })}),_c('div',[_c('h3',{staticClass:"col_title2 b_b_space font-size_24"},[_vm._v(" "+_vm._s(item.title)+" ")]),(index == 0)?_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16",staticStyle:{"padding-left":"0.55rem","padding-right":"0.48rem"}},[_vm._v(" "+_vm._s(item.desc)+" ")]):_vm._e(),(index == 1)?_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16",staticStyle:{"padding-left":"0.21rem","padding-right":"0.18rem"}},[_vm._v(" "+_vm._s(item.desc)+" ")]):_vm._e(),(index == 2)?_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")]):_vm._e()])])}),1)],1)]),_c('a-tab-pane',{key:"2",attrs:{"tab":"满足多证据管理合规化"}},[_c('div',{staticClass:"space_p_t-six"},[_c('a-row',{attrs:{"gutter":80,"type":"flex"}},_vm._l((_vm.helpList1),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":8,"xxl":8}},[_c('div',{staticClass:"bgz",style:({
                      background: ("url(" + (item.img_url) + ")"),
                      width: '76px',
                      height: '76px',
                    })}),_c('div',[_c('h3',{staticClass:"col_title2 b_b_space font-size_24"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)])],1)],1)]),_c('div',{staticClass:"l_r_space t__space informatization_3"},[_c('h2',{staticClass:"nav__title"},[_vm._v("S2P优势")]),_c('p',{staticClass:"nav__desc font-size_24",staticStyle:{"padding":"0.15rem 1.61rem 0.9rem 1.61rem"}}),_c('a-row',{attrs:{"gutter":165,"type":"flex"}},_vm._l((_vm.advantageList),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12,"xxl":12}},[_c('div',{staticClass:"bgz",style:({
              background: ("url(" + (item.img_url) + ")"),
              width: item.width,
              height: item.height,
            })}),_c('div',[_c('h3',{staticClass:"col_title3 b_b_space font-size_26"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 font-size_16",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"desc__box text_left"},[_c('h1',{staticClass:"banner_title b_b_space font-size_60"},[_vm._v(" 合规证据链管理"),_c('br'),_vm._v("解决方案 ")]),_c('p',{staticClass:"banner_desc b_b_space font-size_20"},[_vm._v(" 正也S2P智能管理平台是面向未来的、基于“代表备案制度”体系下的合规证据链系统，集云端、移动端（微信小程序）一体化的应用型工具。为医药企业、营销服务商提供基础信息管理服务，可用于收集与汇总综合服务商、项目服务商的日常工作数据，为医药营销与服务，提供有效的合规证据链。 ")])])])}]

export { render, staticRenderFns }