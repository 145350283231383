<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="banner">
        <div class="desc__box text_left">
          <h1 class="banner_title b_b_space font-size_60">
            合规证据链管理<br />解决方案
          </h1>
          <p class="banner_desc b_b_space font-size_20">
            正也S2P智能管理平台是面向未来的、基于“代表备案制度”体系下的合规证据链系统，集云端、移动端（微信小程序）一体化的应用型工具。为医药企业、营销服务商提供基础信息管理服务，可用于收集与汇总综合服务商、项目服务商的日常工作数据，为医药营销与服务，提供有效的合规证据链。
          </p>
        </div>
      </div>
      <div class="l_r_space t__space informatization_1">
        <h2 class="nav__title">合规管理的困境</h2>
        <div style="padding-top: 0.7rem">
          <a-row :gutter="112" type="flex">
            <a-col
              class="space_p_b-four"
              :xs="12"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="8"
              :xxl="8"
              v-for="(item, index) in statusList"
              :key="index"
            >
              <div
                :style="{
                  background: `url(${item.img_url})`,
                  width: item.width,
                  height: item.height,
                }"
                class="bgz"
              ></div>
              <div>
                <h3 class="col_title1 b_b_space font-size_26">
                  {{ item.title }}
                </h3>
                <p class="col_desc1 text_center font-size_16">
                  {{ item.desc }}
                </p>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="l_r_space t__space bgc_f4fbff informatization_2">
        <h2 class="nav__title">我们能帮您做什么</h2>
        <div class="tab_informatization" style="padding-top: 0.7rem">
          <a-tabs
            default-active-key="1"
            :tabBarStyle="{
              borderBottom: 'none',
            }"
          >
            <a-tab-pane key="1" tab="满足业务层级多元化">
              <div class="space_p_t-six">
                <a-row :gutter="165" type="flex">
                  <a-col
                    class="space_p_b-four"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="8"
                    :xl="8"
                    :xxl="8"
                    v-for="(item, index) in helpList"
                    :key="index"
                  >
                    <div
                      :style="{
                        background: `url(${item.img_url})`,
                        width: '76px',
                        height: '76px',
                      }"
                      class="bgz"
                    ></div>
                    <div>
                      <h3 class="col_title2 b_b_space font-size_24">
                        {{ item.title }}
                      </h3>
                      <p
                        v-if="index == 0"
                        class="col_desc1 b_b_space text_left font-size_16"
                        style="padding-left: 0.55rem; padding-right: 0.48rem"
                      >
                        {{ item.desc }}
                      </p>
                      <p
                        v-if="index == 1"
                        class="col_desc1 b_b_space text_left font-size_16"
                        style="padding-left: 0.21rem; padding-right: 0.18rem"
                      >
                        {{ item.desc }}
                      </p>
                      <p
                        v-if="index == 2"
                        class="col_desc1 b_b_space text_left font-size_16"
                      >
                        {{ item.desc }}
                      </p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="满足多证据管理合规化">
              <div class="space_p_t-six">
                <a-row :gutter="80" type="flex">
                  <a-col
                    class="space_p_b-four"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="12"
                    :xl="8"
                    :xxl="8"
                    v-for="(item, index) in helpList1"
                    :key="index"
                  >
                    <div
                      :style="{
                        background: `url(${item.img_url})`,
                        width: '76px',
                        height: '76px',
                      }"
                      class="bgz"
                    ></div>
                    <div>
                      <h3 class="col_title2 b_b_space font-size_24">
                        {{ item.title }}
                      </h3>
                      <p class="col_desc1 b_b_space text_left font-size_16">
                        {{ item.desc }}
                      </p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <!-- <div class="tab_informatization" style="padding-top: 0.7rem">
          <div class="space_p_t-six">
            <a-row type="flex" style="text-align: center" :gutter="60">
              <a-col
                class="space_p_b-four"
                :xs="24"
                :sm="12"
                :md="12"
                :lg="12"
                :xl="12"
                :xxl="12"
                v-for="(item, index) in helpList3"
                :key="index"
              >
                <div
                  :style="{
                    background: `url(${item.img_url})`,
                    width: '76px',
                    height: '76px',
                  }"
                  class="bgz"
                ></div>

                <div>
                  <h3 class="col_title2 b_b_space font-size_24">
                    {{ item.title }}
                  </h3>
                  <p
                    class="col_desc1 b_b_space text_left font-size_16"
                    style="text-align: center"
                  >
                    {{ item.desc }}
                  </p>
                </div>
              </a-col>
            </a-row>
          </div> -->
        <!-- <a-tabs
            default-active-key="1"
            :tabBarStyle="{
              borderBottom: 'none',
            }"
          >
            <a-tab-pane key="1" tab="代表功能">
              <div class="space_p_t-six">
                <a-row :gutter="165" type="flex">
                  <a-col
                    class="space_p_b-four"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="8"
                    :xl="8"
                    :xxl="8"
                    v-for="(item, index) in helpList"
                    :key="index"
                  >
                    <div
                      :style="{
                        background: `url(${item.img_url})`,
                        width: '76px',
                        height: '76px',
                      }"
                      class="bgz"
                    ></div>
                    <div>
                      <h3 class="col_title2 b_b_space font-size_24">
                        {{ item.title }}
                      </h3>
                      <p class="col_desc1 b_b_space text_left font-size_16">
                        {{ item.desc }}
                      </p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="管理功能">
              <div class="space_p_t-six">
                <a-row :gutter="165" type="flex">
                  <a-col
                    class="space_p_b-four"
                    :xs="24"
                    :sm="12"
                    :md="12"
                    :lg="8"
                    :xl="8"
                    :xxl="8"
                    v-for="(item, index) in helpList1"
                    :key="index"
                  >
                    <div
                      :style="{
                        background: `url(${item.img_url})`,
                        width: '76px',
                        height: '76px',
                      }"
                      class="bgz"
                    ></div>
                    <div>
                      <h3 class="col_title2 b_b_space font-size_24">
                        {{ item.title }}
                      </h3>
                      <p class="col_desc1 b_b_space text_left font-size_16">
                        {{ item.desc }}
                      </p>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
          </a-tabs> -->
        <!-- </div> -->
      </div>
      <div class="l_r_space t__space informatization_3">
        <h2 class="nav__title">S2P优势</h2>
        <p
          class="nav__desc font-size_24"
          style="padding: 0.15rem 1.61rem 0.9rem 1.61rem"
        >
          <!--          S2P综合平台以销售代表为中心，采用开放的平台架构打造贴近药企应用的教学服务-->
        </p>
        <a-row :gutter="165" type="flex">
          <a-col
            class="space_p_b-four"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
            :xxl="12"
            v-for="(item, index) in advantageList"
            :key="index"
          >
            <div
              :style="{
                background: `url(${item.img_url})`,
                width: item.width,
                height: item.height,
              }"
              class="bgz"
            ></div>
            <div>
              <h3 class="col_title3 b_b_space font-size_26">
                {{ item.title }}
              </h3>
              <p class="col_desc1 font-size_16" style="text-align: center">
                {{ item.desc }}
              </p>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import bazaar_1 from "@/assets/img/png/bazaar_1.png";
import bazaar_2 from "@/assets/img/png/bazaar_2.png";
import bazaar_3 from "@/assets/img/png/bazaar_3.png";
import bazaar_4 from "@/assets/img/png/bazaar_4.png";
import bazaar_5 from "@/assets/img/png/bazaar_5.png";
import bazaar_6 from "@/assets/img/png/bazaar_6.png";
import bazaar_7 from "@/assets/img/png/bazaar_7.png";
import bazaar_8 from "@/assets/img/png/bazaar_8.png";
import informatization_7 from "@/assets/img/png/informatization_7.png";
import informatization_8 from "@/assets/img/png/informatization_8.png";
import informatization_9 from "@/assets/img/png/informatization_9.png";
export default {
  name: "bazaarAdministration",
  components: { Footer, HeaderMenu },
  data() {
    return {
      statusList: [
        {
          title: "监管严格",
          width: "87px",
          height: "87px",
          desc: "财税合规风险加剧、数据安全要求愈严",
          img_url: bazaar_4,
        },
        {
          title: "证据缺失",
          width: "87px",
          height: "83px",
          desc: "证据存档无序、溯源查询困难、手工证据比对量大",
          img_url: bazaar_2,
        },
        {
          title: "缺少信息化管理",
          width: "74px",
          height: "73px",
          desc: "人工整理、费用支出查验费时费力",
          img_url: bazaar_8,
        },

        // {
        //   title: "CSO团队的绩效如何考核？",
        //   width: "87px",
        //   height: "89px",
        //   desc: "",
        //   img_url: bazaar_3,
        // },
        // {
        //   title: "如何让CSO团队养成良好的学习习惯？",
        //   width: "90px",
        //   height: "90px",
        //   desc: "",
        //   img_url: bazaar_4,
        // },
      ],
      helpList: [
        {
          title: "上游基础数据管理",
          desc: "工业对CSP机构（综合服务商）的管理：下达年度服务任务，收集证据报告。",
          img_url: informatization_7,
        },
        {
          title: "CSP机构管理",
          desc: "CSP机构对下游CSO服务商的管理：客户关联关系分配、任务目标分解、证据报告审核。",
          img_url: informatization_8,
        },
        {
          title: "服务报告管理",
          desc: "支持代表备案的信息，提供专项报告的管理，对服务商（CSP/CSO）日常工作、服务报告内容、及各项工作清单记录。内置近40套服务报告模板，满足不同企业不同服务类型的需求，提供报告模板订制。",
          img_url: informatization_9,
        },
      ],
      helpList1: [
        {
          title: "证据链",
          desc: "记录有效真实的服务，自动生成第三方证据链。",
          img_url: informatization_7,
        },
        {
          title: "云端记录",
          desc: "云端实时生成记录，可信证据，合规备查。",
          img_url: informatization_8,
        },
        {
          title: "真实可靠",
          desc: "第三方证据链平台，经稽查部门验证，可具真实性。",
          img_url: informatization_9,
        },
      ],
      // helpList: [
      //   {
      //     title: "课程查询",
      //     desc: "登录系统查询课程，选择进行报名、学习、评价",
      //     img_url: informatization_7,
      //   },
      //   {
      //     title: "在线学习",
      //     desc:
      //       "制定完学习计划之后，代表能够在线参加课程学习、章节课后作业、结业考试等",
      //     img_url: informatization_8,
      //   },
      //   {
      //     title: "课程互动",
      //     desc:
      //       "在学习的过程中可以通过系统实现管理者与代表、代表之间的课程互动交流，代表针对课程知识点记录课程笔记。课程考试之后，代表在系统上查询考试成绩，回顾复习考试错题",
      //     img_url: informatization_9,
      //   },
      // ],
      // helpList1: [
      //   {
      //     title: "课程制作",
      //     desc: "通过系统平台添加、删除、修改、审核课程信息",
      //     img_url: informatization_7,
      //   },
      //   {
      //     title: "线上批阅",
      //     desc:
      //       "课程发布后可统计、审核课程报名人员信息，通过系统批阅学生作业、考试试卷",
      //     img_url: informatization_8,
      //   },
      //   {
      //     title: "XXXXXX",
      //     desc: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      //     img_url: informatization_9,
      //   },
      // ],
      // helpList3: [
      //   {
      //     title: "证据缺失",
      //     desc: "服务商、代理商做了什么推广无法提供有效的证据",
      //     img_url: informatization_7,
      //   },
      //   {
      //     title: "缺少沉淀",
      //     desc: "缺少对客户的管理，不了解客户当前的维护情况",
      //     img_url: informatization_8,
      //   },
      // ],
      advantageList: [
        {
          title: "全平台程序",
          width: "70px",
          height: "67px",
          desc: "PC端、app、小程序，满足不同的使用场景",
          img_url: bazaar_5,
        },
        {
          title: " 完全的体系",
          width: "71px",
          height: "71px",
          desc: "服务商代理商评价体系、工作模板，帮助企业管理",
          img_url: bazaar_6,
        },
        /*{
          title: "XXXXXX",
          width: "67px",
          height: "70px",
          desc: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
          img_url: bazaar_7,
        },*/
      ],
    };
  },
  methods: {},
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.col_title1 {
  padding: 0.25rem 0 0.12rem 0;
  color: $color-darkBlue;
  text-align: center;
}
.col_desc1 {
  color: $color-fixGray;
  word-break: break-all;
  margin-bottom: 0;
}
.col_title2 {
  padding: 0.15rem 0 0.2rem 0;
  font-weight: bold;
}
.col_title3 {
  padding: 0.35rem 0 0.12rem 0;
  color: $color-darkMinBlue;
  font-weight: bold;
}
.banner {
  width: 100%;
  position: relative;
  background: url("~@/assets/img/png/bazaar_banner.png");
  background-size: 100% 100%;
  height: 8rem;
  overflow: hidden;
  .desc__box {
    position: absolute;
    top: 1.5rem;
    left: 13.5%;
    .banner_title {
      color: $color-lightBlue;
    }
    .banner_desc {
      width: 4.2rem;
      padding-top: 0.45rem;
      margin-bottom: 0;
    }
  }
}
.informatization_1 {
  padding-bottom: 0.1rem;
}
.informatization_2 {
  padding-bottom: 0.7rem;
}
.informatization_3 {
  padding-bottom: 0.7rem;
}
.bgz {
  background-size: 100% 100%;
  margin: 0 auto;
}
.tab_informatization {
  // /deep/ .ant-tabs-bar {
  //   border-bottom: none;
  //   .ant-tabs-nav-scroll {
  //     color: $color-fixGray;
  //     font-size: 0.26rem;
  //     .ant-tabs-nav .ant-tabs-tab-active {
  //       color: $color-darkBlue;
  //       font-weight: bold;
  //     }
  //   }
  // }
}
.space_p_b-four {
  padding-bottom: 0.4rem;
}
.space_p_t-six {
  padding-top: 0.6rem;
}
</style>
